import React, {Component} from "react";
import {Link} from "react-router-dom";

class TestimonialArea extends Component {
    render() {
        return (
            <div className="testimonial-area testimonial-bg">
                <div className="customer-feedback">
                    <div className="container text-center">
                        <div className="row justify-content-center">
                            <div className="col-md-offset-3 col-md-8 col-sm-offset-2 col-sm-8">
                                <div className="owl-carousel feedback-slider">
                                    {/* slider item */}
                                    <div className="feedback-slider-item">
                                        <img
                                            src={process.env.PUBLIC_URL + '/assets/img/testimonial/01.png'}
                                            className="center-block img-circle"
                                            alt="Customer Feedback" />
                                        <h3 className="customer-name">Alnunia anan</h3>
                                        <span className="title">Stet clita kasd gubergren.</span>
                                        <div className="subline" />
                                        <p>
                                            Lorem ipsum dolor sit amet. eirmod tempor invidunt ut labore et dolore
                                            magna aliquyam erat, sed diam voluptua. At vero eos et accusam et
                                            justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                                            takimata sanctus est
                                        </p>
                                        <div className="line" />
                                        <ul className="feedback-social">
                                            <li>
                                                <Link to={'/contact'} className="initiate-scripts">
                                                    <i className="fa fa-facebook-f" aria-hidden="true" />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={'/contact'} className="initiate-scripts">
                                                    <i className="fa fa-twitter" aria-hidden="true" />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={'/contact'} className="initiate-scripts">
                                                    <i className="fa fa-linkedin" aria-hidden="true" />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={'/contact'} className="initiate-scripts">
                                                    <i className="fa fa-pinterest" aria-hidden="true" />
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* /slider item */}

                                    {/* slider item */}
                                    <div className="feedback-slider-item">
                                        <img
                                            src={process.env.PUBLIC_URL + '/assets/img/testimonial/02.png'}
                                            className="center-block img-circle"
                                            alt="Customer Feedback" />
                                        <h3 className="customer-name">R.M Suthey Ronu</h3>
                                        <span className="title">us est Lorem ipsum dolor.</span>
                                        <div className="subline" />
                                        <p>
                                            Lorem ipsum dolor sit amet. eirmod tempor invidunt ut labore et dolore
                                            magna aliquyam erat, sed diam voluptua. At vero eos et accusam et
                                            justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                                            takimata sanctus est
                                        </p>
                                        <div className="line" />
                                        <ul className="feedback-social">
                                            <li>
                                                <Link to={'/contact'} className="initiate-scripts">
                                                    <i className="fa fa-facebook-f" aria-hidden="true" />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={'/contact'} className="initiate-scripts">
                                                    <i className="fa fa-twitter" aria-hidden="true" />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={'/contact'} className="initiate-scripts">
                                                    <i className="fa fa-linkedin" aria-hidden="true" />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={'/contact'} className="initiate-scripts">
                                                    <i className="fa fa-pinterest" aria-hidden="true" />
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* /slider item */}

                                    {/* slider item */}
                                    <div className="feedback-slider-item">
                                        <img
                                            src={process.env.PUBLIC_URL + '/assets/img/testimonial/03.png'}
                                            className="center-block img-circle"
                                            alt="Customer Feedback" />
                                        <h3 className="customer-name">Dr. Abinton Rak</h3>
                                        <span className="title">sam et justo duo dolores. </span>
                                        <div className="subline" />
                                        <p>
                                            Lorem ipsum dolor sit amet. eirmod tempor invidunt ut labore et dolore
                                            magna aliquyam erat, sed diam voluptua. At vero eos et accusam et
                                            justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea
                                            takimata sanctus est
                                        </p>
                                        <div className="line" />
                                        <ul className="feedback-social">
                                            <li>
                                                <Link to={'/contact'} className="initiate-scripts">
                                                    <i className="fa fa-facebook-f" aria-hidden="true" />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={'/contact'} className="initiate-scripts">
                                                    <i className="fa fa-twitter" aria-hidden="true" />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={'/contact'} className="initiate-scripts">
                                                    <i className="fa fa-linkedin" aria-hidden="true" />
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={'/contact'} className="initiate-scripts">
                                                    <i className="fa fa-pinterest" aria-hidden="true" />
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    {/* /slider item */}
                                </div>{/* /End feedback-slider */}

                                {/* side thumbnail */}
                                <div className="feedback-slider-thumb hidden-xs">
                                    <div className="thumb-prev">
                                        <div>
                                            <img
                                                src={process.env.PUBLIC_URL + '/assets/img/testimonial/02.png'}
                                                alt="Customer Feedback" />
                                            <h3 className="customer-name">R.M Suthey Ronu</h3>
                                            <span className="title">us est Lorem ipsum dolor .</span>
                                        </div>
                                    </div>
                                    <div className="thumb-next">
                                        <div>
                                            <img
                                                src={process.env.PUBLIC_URL + '/assets/img/testimonial/03.png'}
                                                alt="Customer Feedback" />
                                            <h3 className="customer-name">Dr. Abinton Rak</h3>
                                            <span className="title">sam et justo duo dolores .</span>
                                        </div>
                                    </div>
                                </div>
                                {/* /side thumbnail */}
                            </div>{/* /End col */}
                        </div>{/* /End row */}
                    </div>{/* /End container */}
                </div>{/* /End customer-feedback */}
            </div>
        );
    }
}

export default TestimonialArea;