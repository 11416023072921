import React, { Component } from "react";
import { Link } from "react-router-dom";

class Footer extends Component {
    render() {
        return (
            <>
                {/* footer area start */}
                <footer className="footer-area">
                    <div className="footer-top padding-top-100 padding-bottom-65">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-3 col-md-3">
                                    <div className="footer-widget widget">
                                        <div className="about_us_widget">
                                            <Link to={'/'} className="footer-logo initiate-scripts">
                                                <img
                                                    src={process.env.PUBLIC_URL + '/assets/img/white-logo.png'}
                                                    alt="footer logo" />
                                            </Link>
                                            
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-3 col-md-3"></div>
                                <div className="col-lg-6 col-md-6">
                                    <div className="footer-widget widget widget_nav_menu">
                                        <h4 className="widget-title">Contact</h4>
                                        <ul className="contact_info_list">
                                                <li className="single-info-item">
                                                    <div className="icon">
                                                        <i className="fa fa-home" />
                                                    </div>
                                                    <div className="details">
                                                        Unit 4, 22-24 Norman Street, Peakhurst NSW 2210
                                                    </div>
                                                </li>
                                                <li className="single-info-item">
                                                    <div className="icon">
                                                        <i className="fa fa-phone" />
                                                    </div>
                                                    <div className="details">
                                                    0451 874 349
                                                    </div>
                                                </li>
                                                <li className="single-info-item">
                                                    <div className="icon">
                                                        <i className="fa fa-fax" />
                                                    </div>
                                                    <div className="details">
                                                        02-9153 9986
                                                    </div>
                                                </li>
                                                <li className="single-info-item">
                                                    <div className="icon">
                                                        <i className="fa fa-envelope-o" />
                                                    </div>
                                                    <div className="details">
                                                        airjunction@outlook.com
                                                    </div>
                                                </li>
                                            </ul>
                                        {/* <ul>
                                            <li><Link to={'/service'} className="initiate-scripts">A/C Installation</Link></li>
                                            <li><Link to={'/contact'} className="initiate-scripts">FAQs</Link></li>
                                            <li><Link to={'/about'} className="initiate-scripts">About Us</Link></li>
                                            <li><Link to={'/contact'} className="initiate-scripts">Contact Us</Link></li>
                                            <li><Link to={'/'} className="initiate-scripts">Home</Link></li>
                                        </ul> */}
                                    </div>
                                </div>

                                
                            </div>
                        </div>
                    </div>
                    {/* <div className="copyright-area">
                        <div className="container">
                            <div className="row">
                                <div className="col-md-7">
                                    <div className="footer-menu">
                                        <ul>
                                            <li><Link to={'/'} className="initiate-scripts">Terms & Conditions</Link></li>
                                            <li><Link to={'/'} className="initiate-scripts">Privacy Policy</Link></li>
                                            <li><Link to={'/'} className="initiate-scripts">Sitemap</Link></li>
                                        </ul>
                                    </div>

                                </div>
                                <div className="col-md-5">
                                    <div className="copyright-text">
                                        <span> &copy; 2020, Cooly. all right reserved .</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                </footer>
                {/* footer area end */}


                {/* back to top area start */}
                <div className="back-to-top">
                    <span className="back-top"><i className="fa fa-angle-up" /></span>
                </div>
                {/* back to top area end */}
            </>
        );
    }
}

export default Footer;