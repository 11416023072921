import React, {Component} from "react";
import {Link} from "react-router-dom";

class AboutArea extends Component {
    render() {
        return (
            <div className="about-us-area about-bg" id="about">
                <div className="container-fluid">
                    <div className="row justify-content-start">
                        <div className="col-lg-5 remove-col-padding">
                            <div className="about-image">
                                <img
                                    src={'/assets/img/bg/about.jpg'}
                                    className="img-fluid"
                                    alt="about us" />


                            </div>
                        </div>
                        <div className="col-lg-7 align-self-center">
                            <div className="about-area-right mb-0">
                                <div className="section-title about text-left mb-0">
                                    <span className="subtitle">About Air Junction  </span>
                                    <h2 className="title">Experience comfort with our expert installations</h2>
                                </div>
                                <div className="faq-wrap">
                                    <ul className="accordion">
                                        <li className="active">
                                            <div className="question"><h4> About Us</h4>
                                                <div className="plus-minus-toggle collapsed" />
                                            </div>
                                            <div className="answer">
                                            Air Junction Pty Ltd supplies air conditioning installation parts and components. We stock quality HVAC products for both residential and commercial applications. We are aiming to provide the goods and services with quality, availability and reliability. 

                                            </div>
                                        </li>
                                        {/* <li>
                                            <div className="question"><h4> 18+ Years Experience</h4>
                                                <div className="plus-minus-toggle collapsed" />
                                            </div>
                                            <div className="answer">
                                                Takimata sanctus est Lorem ipsum dolor sit amet. publishing rem Ipsu
                                                default model text. Stet clita kasd gubergren,
                                                no sea takimata sanctus est Lorem ipsum dolor sit amet.
                                            </div>
                                        </li>
                                        <li>
                                            <div className="question"><h4> Online Schedule</h4>
                                                <div className="plus-minus-toggle collapsed" />
                                            </div>
                                            <div className="answer">
                                                Takimata sanctus est Lorem ipsum dolor sit amet. publishing rem Ipsu
                                                default model text. Stet clita kasd gubergren, no sea takimata sanctus
                                                est Lorem ipsum dolor sit amet.
                                            </div>
                                        </li>
                                        <li>
                                            <div className="question"><h4> Financing Available</h4>
                                                <div className="plus-minus-toggle collapsed" />
                                            </div>
                                            <div className="answer">
                                                Takimata sanctus est Lorem ipsum dolor sit amet. publishing rem Ipsu
                                                default model text. Stet clita kasd gubergren, no sea takimata sanctus
                                                est Lorem ipsum dolor sit amet.
                                            </div>
                                        </li> */}
                                    </ul>
                                </div>

                                <div className="btn-wrapper">
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default AboutArea;