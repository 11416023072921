import React, {Component} from "react";
import Section from "./layouts/Section";
import Header from "./layouts/Header";
import CooliItemArea from "./layouts/CooliItemArea";
import AboutArea from "./layouts/AboutArea";
import AskArea from "./layouts/AskArea";
import ServiceArea from "./layouts/ServiceArea";
import CountArea from "./layouts/CountArea";
import FacilitiesArea from "./layouts/FacilitiesArea";
import ProjectArea from "./layouts/ProjectArea";
import CtaArea from "./layouts/CtaArea";
import HistoryArea from "./layouts/HistoryArea";
import ChooseArea from "./layouts/ChooseArea";
import BlogArea from "./layouts/BlogArea";
import TestimonialArea from "./layouts/TestimonialArea";
import NewsletterArea from "./layouts/NewsletterArea";

class HomeOne extends Component {
    render() {
        return (
            <Section design={'home_1'} breadcrumb={false}>
                <Header design={'home_1'} />
                {/* <CooliItemArea design={'home_1'} /> */}
                <AboutArea id="about" />
                {/* <AskArea /> */}
                <ServiceArea service={false} />
                {/* <CountArea /> */}
                <FacilitiesArea />
                {/* <ProjectArea /> */}
                <CtaArea service={false} />
                {/* <HistoryArea />
                <ChooseArea />
                <BlogArea />
                <TestimonialArea />
                <NewsletterArea /> */}
            </Section>
        );
    }
}

export default HomeOne;