import React, {Component} from "react";
import {Link} from "react-router-dom";

class ProjectArea extends Component {
    render() {
        return (
            <div className="project-area pd-top-100 pd-bottom-70" id="project">
                <div className="project-bg" />
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-7 col-lg-9 text-center">
                            <div className="section-title white project">
                                <span className="subtitle">projects deals</span>
                                <h2 className="title">Awesome Projects</h2>
                                <p>
                                    Lorem ipsum dolor sit amet, consectetur
                                    adipiscing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="project-masonry-wrapper">
                                <div className="our-project-menu">
                                    <ul>
                                        <li className="active" data-filter="*">All</li>
                                        <li data-filter=".remote">Remote Air Conditioner</li>
                                        <li data-filter=".gloves">Gloves Checks</li>
                                        <li data-filter=".conditioner">Conditioner Thermometer</li>
                                    </ul>
                                </div>
                                <div className="project-masonry" id="project-masonry">
                                    <div className="col-lg-4 col-md-6 filtr-item yg_filter_item_padding grid-size remote">
                                        <div className="inner-box">
                                            <div className="image">
                                                <img src={process.env.PUBLIC_URL + '/assets/img/project/01.png'} alt="" />
                                                <div className="img-overlay">
                                                    <div className="icon">
                                                        <a
                                                            href={process.env.PUBLIC_URL + '/assets/img/project/01.png'}
                                                            className="imagepopup popup-link"
                                                            data-effect="zoomInUp">

                                                            <i className="fa fa-search-plus" aria-hidden="true"/>
                                                        </a>
                                                    </div>
                                                    <div className="content-area">
                                                        <Link to={'/service'} className="initiate-scripts">
                                                            <h4 className="title">Remote Air Conditioner</h4>
                                                        </Link>
                                                        <div className="category">
                                                            <Link to={'/service'} className="initiate-scripts">best consulting,</Link>
                                                            <Link to={'/service'} className="initiate-scripts">for business</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 filtr-item yg_filter_item_padding grid-size gloves">
                                        <div className="inner-box">
                                            <div className="image">
                                                <img src={process.env.PUBLIC_URL + '/assets/img/project/02.png'} alt="" />
                                                <div className="img-overlay">
                                                    <div className="icon">
                                                        <a
                                                            href={process.env.PUBLIC_URL + '/assets/img/project/02.png'}
                                                            className="imagepopup popup-link"
                                                            data-effect="zoomInUp">
                                                            <i className="fa fa-search-plus" aria-hidden="true"/>
                                                        </a>
                                                    </div>
                                                    <div className="content-area">
                                                        <Link to={'/service'} className="initiate-scripts">
                                                            <h4 className="title">Remote Air Conditioner</h4>
                                                        </Link>
                                                        <div className="category">
                                                            <Link to={'/service'} className="initiate-scripts">best consulting,</Link>
                                                            <Link to={'/service'} className="initiate-scripts">for business</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 filtr-item yg_filter_item_padding grid-size conditioner remote">
                                        <div className="inner-box">
                                            <div className="image">
                                                <img src={process.env.PUBLIC_URL + '/assets/img/project/03.png'} alt="" />
                                                <div className="img-overlay">
                                                    <div className="icon">
                                                        <a
                                                            href={process.env.PUBLIC_URL + '/assets/img/project/03.png'}
                                                            className="imagepopup popup-link"
                                                            data-effect="zoomInUp">
                                                            <i className="fa fa-search-plus" aria-hidden="true"/>
                                                        </a>
                                                    </div>
                                                    <div className="content-area">
                                                        <Link to={'/service'} className="initiate-scripts">
                                                            <h4 className="title">Remote Air Conditioner</h4>
                                                        </Link>
                                                        <div className="category">
                                                            <Link to={'/service'} className="initiate-scripts">best consulting,</Link>
                                                            <Link to={'/service'} className="initiate-scripts">for business</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 filtr-item grid-size yg_filter_item_padding conditioner">
                                        <div className="inner-box">
                                            <div className="image">
                                                <img src={process.env.PUBLIC_URL + '/assets/img/project/04.png'} alt="" />
                                                <div className="img-overlay">
                                                    <div className="icon">
                                                        <a
                                                            href={process.env.PUBLIC_URL + '/assets/img/project/04.png'}
                                                            className="imagepopup popup-link"
                                                            data-effect="zoomInUp">
                                                            <i className="fa fa-search-plus" aria-hidden="true"/>
                                                        </a>
                                                    </div>
                                                    <div className="content-area">
                                                        <Link to={'/service'} className="initiate-scripts">
                                                            <h4 className="title">Remote Air Conditioner</h4>
                                                        </Link>
                                                        <div className="category">
                                                            <Link to={'/service'} className="initiate-scripts">best consulting,</Link>
                                                            <Link to={'/service'} className="initiate-scripts">for business</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 filtr-item grid-size yg_filter_item_padding gloves">
                                        <div className="inner-box">
                                            <div className="image">
                                                <img src={process.env.PUBLIC_URL + '/assets/img/project/05.png'} alt="" />
                                                <div className="img-overlay">
                                                    <div className="icon">
                                                        <a
                                                            href={process.env.PUBLIC_URL + '/assets/img/project/05.png'}
                                                            className="imagepopup popup-link"
                                                            data-effect="zoomInUp">
                                                            <i className="fa fa-search-plus" aria-hidden="true"/>
                                                        </a>
                                                    </div>
                                                    <div className="content-area">
                                                        <Link to={'/service'} className="initiate-scripts">
                                                            <h4 className="title">Remote Air Conditioner</h4>
                                                        </Link>
                                                        <div className="category">
                                                            <Link to={'/service'} className="initiate-scripts">best consulting,</Link>
                                                            <Link to={'/service'} className="initiate-scripts">for business</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-6 filtr-item grid-size yg_filter_item_padding remote gloves">
                                        <div className="inner-box">
                                            <div className="image">
                                                <img src={process.env.PUBLIC_URL + '/assets/img/project/06.png'} alt="" />
                                                <div className="img-overlay">
                                                    <div className="icon">
                                                        <a
                                                            href={process.env.PUBLIC_URL + '/assets/img/project/06.png'}
                                                            className="imagepopup popup-link"
                                                            data-effect="zoomInUp">
                                                            <i className="fa fa-search-plus" aria-hidden="true"/>
                                                        </a>
                                                    </div>
                                                    <div className="content-area">
                                                        <Link to={'/service'} className="initiate-scripts">
                                                            <h4 className="title">Remote Air Conditioner</h4>
                                                        </Link>
                                                        <div className="category">
                                                            <Link to={'/service'} className="initiate-scripts">best consulting,</Link>
                                                            <Link to={'/service'} className="initiate-scripts">for business</Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-lg-2">
                            <div className="btn-wrapper text-center">
                                <Link to={'/shop'} className="boxed-btn btn-rounded">View All Project</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProjectArea;