import React, {Component} from "react";
import {Link} from "react-router-dom";

class CooliItemArea extends Component {
    render() {
        const designOne = (
            <div className="row">
                <div className="col-lg-12">
                    <div className="item-carousel owl-carousel">
                        <div className="single-cooli-item item-bg">
                            <div className="icon">
                                <i className="flaticon-shield" aria-hidden="true" />
                            </div>
                            <h4 className="title">Warrantees & Gurantees</h4>
                            <p className="details">
                                Desktop publishing packages and page editors now use Lorem Ipsu default model text.
                            </p>
                            <div className="btn-wrapper text-center">
                                <Link to={'/services'} className="read initiate-scripts">Read More</Link>
                            </div>
                        </div>
                        <div className="single-cooli-item active item-bg">
                            <div className="icon">
                                <i className="flaticon-emergency-call" aria-hidden="true" />
                            </div>
                            <h4 className="title">Emergency Service</h4>
                            <p className="details">
                                Desktop publishing packages and page editors now use Lorem Ipsu default model text.
                            </p>
                            <div className="btn-wrapper text-center">
                                <Link to={'/services'} className="read initiate-scripts">Read More</Link>
                            </div>
                        </div>
                        <div className="single-cooli-item item-bg">
                            <div className="icon">
                                <i className="flaticon-payment" aria-hidden="true" />
                            </div>
                            <h4 className="title">Satisfaction Guarantee</h4>
                            <p className="details">
                                Desktop publishing packages and page editors now use Lorem Ipsu default model text.
                            </p>
                            <div className="btn-wrapper text-center">
                                <Link to={'/services'} className="read initiate-scripts">Read More</Link>
                            </div>
                        </div>
                        <div className="single-cooli-item item-bg">
                            <div className="icon">
                                <i className="flaticon-emergency-call" aria-hidden="true" />
                            </div>
                            <h4 className="title">Emergency Service</h4>
                            <p className="details">
                                Desktop publishing packages and page editors now use Lorem Ipsu default model text.
                            </p>
                            <div className="btn-wrapper text-center">
                                <Link to={'/services'} className="read initiate-scripts">Read More</Link>
                            </div>
                        </div>
                        <div className="single-cooli-item item-bg">
                            <div className="icon">
                                <i className="flaticon-payment" aria-hidden="true" />
                            </div>
                            <h4 className="title">Satisfaction Guarantee</h4>
                            <p className="details">
                                Desktop publishing packages and page editors now use Lorem Ipsu default model text.
                            </p>
                            <div className="btn-wrapper text-center">
                                <Link to={'/services'} className="read initiate-scripts">Read More</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );

        const designTwo = (
            <div className="row justify-content-center">
                <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="single-cooli-item item-bg">
                        <div className="icon">
                            <i className="flaticon-shield" aria-hidden="true" />
                        </div>
                        <h4 className="title">Warrantees & Gurantees</h4>
                        <p className="details">
                            Desktop publishing packages and page editors now use Lorem Ipsu default model text.
                        </p>
                        <div className="btn-wrapper text-center">
                            <Link to={'/services'} className="read initiate-scripts">Read More</Link>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="single-cooli-item item-bg">
                        <div className="icon">
                            <i className="flaticon-emergency-call" aria-hidden="true" />
                        </div>
                        <h4 className="title">Emergency Service</h4>
                        <p className="details">
                            Desktop publishing packages and page editors now use Lorem Ipsu default model text.
                        </p>
                        <div className="btn-wrapper text-center">
                            <Link to={'/services'} className="read initiate-scripts">Read More</Link>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="single-cooli-item item-bg">
                        <div className="icon">
                            <i className="flaticon-payment" aria-hidden="true" />
                        </div>
                        <h4 className="title">Satisfaction Guarantee</h4>
                        <p className="details">
                            Desktop publishing packages and page editors now use Lorem Ipsu default model text.
                        </p>
                        <div className="btn-wrapper text-center">
                            <Link to={'/services'} className="read initiate-scripts">Read More</Link>
                        </div>
                    </div>
                </div>
            </div>
        );
        
        return (
            <div className={this.props.design === 'home_2' ? 'cooli-item-area style-02' : 'cooli-item-area pd-bottom-100'}>
                <div className="container cooli-item-bg">
                    {this.props.design === 'home_2' ? designTwo : designOne}
                </div>
            </div>
        );
    }
}

export default CooliItemArea;