import React, {Component} from "react";
import {Link} from "react-router-dom";

class CountArea extends Component {
    render() {
        return (
            <div className="counterup-area pd-top-90 pd-bottom-95" id="counterup">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-4">
                            <div className="countr lt">
                                <span className="counter">1245</span>
                                <h3 className="title">Customers</h3>
                                <p>Customer very satified with our work</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="countr">
                                <span className="counter">45</span>
                                <h3 className="title">Our Branches</h3>
                                <p>45 Branches in NewYork, USA</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-4">
                            <div className="countr">
                                <span className="counter">33245</span>
                                <h3 className="title">Successfuly works</h3>
                                <p>Customer very satified with our work</p>
                            </div>
                        </div>
                    </div>

                    <div className="row justify-content-center">
                        <div className="col-xl-7 col-lg-9">
                            <div className="contacy">
                                <p>
                                    Lorem ipsum dolor sit amet. eirmod tempor invidunt ut
                                    labore et dolore magna aliquyam erat, sed diam voluptua.
                                    At vero eos et accusam et justo duo dolores et ea rebum.
                                    Stet clita kasd gubergren, no sea takimata sanctus est
                                </p>
                                <div className="btn-wrapper">
                                    <Link
                                        to={'/contact'}
                                        className="boxed-btn btn-hrv initiate-scripts">
                                        Contacy Us For Conditioning
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default CountArea;