import React, {Component} from "react";
import {Link} from "react-router-dom";

class InfoBar extends Component {
    constructor(props) {
        super(props);
        this.state = {quote: false}
    }

    render() {
        return (
            <>
                <div className={this.state.quote ? 'body-overlay active' : 'body-overlay'} id="body-overlay" onClick={() => this.setState({quote: false})} />

                <div id="quoteForm" className={this.state.quote ? 'form_wrapper active' : 'form_wrapper'} style={{display: this.state.quote ? 'block' : 'none'}}>
                    <h2 className="headline">Book An Appointment</h2>
                    <span className="subtitle">Don’t Worry </span>
                    <h3 className="title"> We are very expereianced</h3>
                    <form className="">
                        <div className="input_field"><span><i aria-hidden="true" className="fa fa-user" /></span>
                            <input type="text" name="nane" placeholder="Your Full Name" required />
                        </div>
                        <div className="input_field"><span><i aria-hidden="true" className="fa fa-envelope" /></span>
                            <input type="email" name="email" placeholder="Your Email" required/>
                        </div>
                        <div className="input_field select_option">
                            <span><i className="fa fa-cog" aria-hidden="true" /></span>
                            <select>
                                <option>Select a Service</option>
                                <option>Option 1</option>
                                <option>Option 2</option>
                            </select>
                            <div className="select_arrow" />
                        </div>
                        <div className="row clearfix">
                            <div className="col_half">
                                <div className="input_field"><span><i aria-hidden="true" className="fa fa-phone" /></span>
                                    <input type="text" name="phone" placeholder="Phone Number"/>
                                </div>
                            </div>
                            <div className="col_half">
                                <div className="input_field"><span><i aria-hidden="true" className="fa fa-map-marker" /></span>
                                    <input type="text" name="zip" placeholder="Zip Code" required/>
                                </div>
                            </div>
                        </div>
                        <input className="button" type="submit" value="Submit"/>
                    </form>
                </div>

                <div className="info-bar-area">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="info-bar-inner">
                                    <div className="logo-wrapper desktop-logo">
                                        <Link to={'/'} className="logo initiate-scripts">
                                            <img src={process.env.PUBLIC_URL + '/assets/img/airjunction.png'} alt="logo" style={{width:180}} />
                                        </Link>
                                    </div>
                                    <div className="right-content">
                                        <ul className="info-items">
                                            <li>
                                                <div className="single-info-item">
                                                    <div className="icon">
                                                        <i className="fa fa-map-marker" aria-hidden="true" />
                                                    </div>
                                                    <div className="content">
                                                        <h5 className="title">Address:</h5>
                                                        <span className="details">Unit 4, 22-24 Norman Street, Peakhurst NSW 2210 </span>
                                                    </div>
                                                </div>
                                            </li>
                                            <li>
                                                <div className="single-info-item">
                                                    <div className="icon">
                                                        <i className="fa fa-envelope" aria-hidden="true" />
                                                    </div>
                                                    <div className="content">
                                                        <h5 className="title">Email:</h5>
                                                        <span className="details">airjunction@outlook.com </span>
                                                    </div>
                                                </div>
                                            </li>
                                        </ul>
 {/*                                        <div className="request-quote">
                                            <Link
                                                to={'/contact'}
                                                id="quote"
                                                onClick={(event) => {event.preventDefault(); this.setState({quote: true})}}
                                                className="rq-btn initiate-scripts">Get a quote</Link>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default InfoBar;