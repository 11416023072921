import React, {Component} from "react";

class FacilitiesArea extends Component {
    render() {
        return (
            <div className="facilities-area pd-top-100 pd-bottom-70">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-lg-8 text-center">
                            <div className="section-title">
                                <span className="subtitle">Why choose us</span>
                                <h2 className="title">Why Choose Our Service Facilities?</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                            <div className="single-facilities-item item-bg">
                                <div className="icon">
                                    <i className="flaticon-emergency-call" aria-hidden="true" />
                                </div>
                                <h4 className="title">Customer service and technical support</h4>
                                <p className="details">We prioritize customer satisfaction and provides high-quality customer service and technical support</p>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                            <div className="single-facilities-item item-bg">
                                <div className="icon">
                                    <i className="flaticon-cogwheel" aria-hidden="true" />
                                </div>
                                <h4 className="title">Product quality and diversity</h4>
                                <p className="details">We offers high-quality air conditioning accessories with a wide range of product choices</p>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-6 col-sm-6">
                            <div className="single-facilities-item item-bg">
                                <div className="icon">
                                    <i className="flaticon-man" aria-hidden="true" />
                                </div>
                            <h4 className="title">Reliable supply chain and trade</h4>
                                <p className="details">We have established a reliable supply chain network to ensure timely trade of products</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default FacilitiesArea;