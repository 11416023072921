import React, { Component } from "react";
import {Router, Route, Switch, HashRouter, BrowserRouter} from "react-router-dom";
import history from "./History";
import HomeOne from "./comonents/HomeOne";
import HomeTwo from "./comonents/HomeTwo";
import About from "./comonents/About";
import Service from "./comonents/Service";
import ServiceSingle from "./comonents/ServiceSingle";
import Shop from "./comonents/Shop";
import ShopDetails from "./comonents/ShopDetails";
import Blog from "./comonents/Blog";
import BlogDetails from "./comonents/BlogDetails";
import Contact from "./comonents/Contact";

class Routes extends Component {
    render() {
        return (
            <Router history={history}>
                <BrowserRouter basename="/">
                    <Switch>
                        <Route exact path={'/'} render={(props) => (<HomeOne {...props} />)} />
                        <Route exact path={'/home'} render={(props) => (<HomeOne {...props} />)} />
                        {/* <Route exact path={'/home-one'} render={(props) => (<HomeOne {...props} />)} />
                        <Route exact path={'/home-two'} render={(props) => (<HomeTwo {...props} />)} />
                        <Route exact path={'/about'} render={(props) => (<About {...props} />)} />
                        <Route exact path={'/service'} render={(props) => (<Service {...props} />)} />
                        <Route exact path={'/service-single'} render={(props) => (<ServiceSingle {...props} />)} />
                        <Route exact path={'/shop'} render={(props) => (<Shop {...props} />)} />
                        <Route exact path={'/shop-details'} render={(props) => (<ShopDetails {...props} />)} />
                        <Route exact path={'/blog'} render={(props) => (<Blog {...props} />)} />
                        <Route exact path={'/blog-details'} render={(props) => (<BlogDetails {...props} />)} />
                        <Route exact path={'/contact'} render={(props) => (<Contact {...props} />)} /> */}
                    </Switch>
                </BrowserRouter>
            </Router>
        )
    }
}

export default Routes;