import React, {Component} from "react";
import Section from "./layouts/Section";
import AskArea from "./layouts/AskArea";
import CooliItemArea from "./layouts/CooliItemArea";
import CountArea from "./layouts/CountArea";
import FacilitiesArea from "./layouts/FacilitiesArea";
import CtaArea from "./layouts/CtaArea";
import HistoryArea from "./layouts/HistoryArea";

class About extends Component {
    render() {
        return (
            <Section design={'home_1'} breadcrumb={true} name={'About Us'} title={'Welcome to Air Junction Comapany'}>
                <div className="about-area about-page">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-lg-12">
                                <div className="about-wrapper">
                                    <div className="about-image">
                                        <img
                                            src={ '/assets/img/bg/about.jpg'}
                                            className="img-fluid"
                                            alt="about" />
                                        
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-10">
                                <div className="about-area-right">
                                    <div className="section-title about text-left">
                                        <span className="subtitle">About Air Junction  </span>
                                        <h2 className="title about-page">Experience comfort with our expert installations</h2>
                                    </div>
                               
                                    <div className="faq-wrap">
                                    <ul className="accordion">
                                        <li className="active">
                                            <div className="question"><h4> About Us</h4>
                                                <div className="plus-minus-toggle collapsed" />
                                            </div>
                                            <div className="answer">
                                            Air Junction Pty Ltd supplies air conditioning installation parts and components. We stock quality HVAC products for both residential and commercial applications. We are aiming to provide the goods and services with quality, availability and reliability. 

                                            </div>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Ask Area Imported form Layouts */}
                <AskArea />

                {/* Cooli Item Area Imported form Layouts */}
                <CooliItemArea design={'home_2'} />

                {/* Count Area Imported form Layouts */}
                <CountArea />

                {/* Facitilities Area Imported form Layouts */}
                <FacilitiesArea />

                {/* Cta Area Imported form Layouts */}
                <CtaArea service={false} />

                {/* History Area Imported form Layouts */}
                <HistoryArea />
            </Section>
        );
    }
}

export default About;