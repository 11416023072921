import React, {Component} from "react";

class ServiceArea extends Component {
    render() {
        const moreServices = (
            <>
                <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="single-service-item text-center">
                        <div >
                            <img className="product-img" src="/assets/img/shop/dampers.jpeg"></img>
                            {/* <i className="flaticon-technical-support" aria-hidden="true" /> */}
                        </div>
                        <h5 className="title">Dampers</h5>
                        <p className="details">
                            
                        </p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="single-service-item text-center">
                    <div >
                            <img className="product-img" src="/assets/img/shop/metalbox.jpeg"></img>
                            {/* <i className="flaticon-technical-support" aria-hidden="true" /> */}
                        </div>
                        <h5 className="title">Metal boxes</h5>
                        <p className="details">
                            
                        </p>
                    </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-6">
                    <div className="single-service-item text-center ">
                        <div >
                            <img className="product-img" src="/assets/img/shop/acc.png"></img>
                            {/* <i className="flaticon-technical-support" aria-hidden="true" /> */}
                        </div>
                        <h5 className="title">Accessories</h5>
                        <p className="details">
                        </p>
                    </div>
                </div>
            </>
        );

        return (
            <div className={this.props.service ? 'service-area service-page pd-top-100' : 'service-area pd-top-100 pd-bottom-70'} id="product">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xl-7 col-lg-8 text-center">
                            <div className="section-title">
                                <span className="subtitle">Air Junction Products</span>
                                <h2 className="title">Our Products</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="single-service-item text-center">
                                <div className="">
                                     <img className="product-img" src="/assets/img/shop/airgrill.png"></img>
                                    {/* <i className="flaticon-technical-support" aria-hidden="true" /> */}
                                </div>
                                <h5 className="title">Air Grilles</h5>
                                <p className="details">
                                
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="single-service-item text-center">
                                <div className="">
                                    <img className="product-img" src="/assets/img/shop/flexibleducting.jpeg"></img>
                                    {/* <i className="flaticon-technical-support" aria-hidden="true" /> */}
                                </div>
                                <h5 className="title">Flexible Ducts</h5>
                                <p className="details">
                                 
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-6">
                            <div className="single-service-item text-center ">
                                <div className="">
                                    <img className="product-img" src="/assets/img/shop/fittings.jpeg"></img>
                                    {/* <i className="flaticon-technical-support" aria-hidden="true" /> */}
                                </div>
                                <h5 className="title">Fittings</h5>
                                <p className="details">
                                 
                                </p>
                            </div>
                        </div>

                        {!this.props.service ? moreServices : ''}
                    </div>
                </div>
            </div>
        );
    }
}

export default ServiceArea;